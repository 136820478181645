import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

const pageMeta = {
  title: 'Tango',
  type: 'tango',
  description: 'Information about Argentine tango and queer tango.',
  keywords: `tango, queer tango, lou groshek, lou, groshek`,
}

const TangoPage = ({ location }) => {
  return (
    <Layout
      location={location}
      pageType={pageMeta.type}
      className={`page-${pageMeta.location}`}
    >
      <Row className="heading row">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <h1>{pageMeta.title}</h1>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col
          className="col-desc align-items-center"
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <StaticImage
            src="../images/nyc_qtf.jpg"
            transformOptions={{ grayscale: true }}
          />
          <div className="content">
            <p>
              You have probably seen rehearsed, choreographed, ballroom-style
              tango moves on shows like <i>Dancing with the Stars</i>. What most
              people do not know is that those performances have very little in
              common with Argentine tango as it is danced socially in Argentina
              and around the world. Social tango dancing almost never includes
              the kinds of over the top moves you have seen on television.
              Argentine tango is a very grounded, connected, improvisational,
              and somewhat intellectual dance. It takes years to learn, and the
              tango community likewise has special codes of conduct for both
              dancing and how to comport oneself at the dance venue.
            </p>
            <p>
              The complexity of tango and the tango scene is what makes for such
              a rewarding lifelong endeavor. But this complexity also presents
              many barriers to entry. While I've been studying and dancing
              Argentine tango since 2014, both locally and on urban scenes
              including Chicago and NYC, I am not the most expert dancer. What I
              offer is feminist, body-positive tango instruction that is
              sensitive to queer cultures and identities. I offer lessons for
              beginner- and intermediate-level individuals, couples, and groups.
              While I may review rote steps, I focus on kinesthetics.
              Understanding how you and your dance partner move, what happens
              during a step, a weight shift, a pivot, is far more important than
              memorizing the 8-count basic. I also familiarize students with the
              codigos they will encounter once they start attending milongas. My
              goal for students is to increase their technique and fluency so
              that they can enjoy the queer and straight tango scenes.
            </p>
            <p>
              When we grow up queer, we often learn that our most natural
              postures and movements are not culturally acceptable. Or that our
              bodies are not what we wanted them to be. As a result of trying to
              move in more culturally acceptable ways, or trying to hide parts
              of our bodies with which we aren't comfortable, we sometimes end
              up with habits that are bad for our posture and interfere with
              graceful, powerful movement. Tango demands good posture and
              balance, and you may find that it challenges bad habits that have
              become part of your embodiment of queerness or of gender. But this
              doesn't have to be a bad thing. In fact, it is a rare opportunity
              to increase the health and mobility of your already authentic
              embodiment.
            </p>
            <Link to="/contact/" className="btn btn-primary">
              Contact Lou
            </Link>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default TangoPage

export const Head = () => <SEO meta={{ ...pageMeta }} />
